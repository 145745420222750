.navbar {
    width: 100%;
    height: 100%;
    padding: var(--mantine-spacing-md);
    display: flex;
    flex-direction: column;
    border-right: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.footer {
    padding-top: var(--mantine-spacing-md);
    margin-top: auto;
    margin-bottom: 0;
    border-top: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.link {
    display: flex;
    align-items: center;
    gap: 5px;
    text-decoration: none;
    font-size: var(--mantine-font-size-sm);
    color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-1));
    padding: var(--mantine-spacing-xs) var(--mantine-spacing-sm);
    border-radius: var(--mantine-radius-sm);
    font-weight: 500;
  
    &:hover {
      background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
      color: #000;
  
      .linkIcon {
        color: #000;
      }
    }
  
    &[data-active] {
      &, &:hover {
        background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
        color: #000;
  
        .linkIcon {
          color: #000;
        }
      }
    }
}

.linkIcon {
    color: light-dark(var(--mantine-color-gray-6), var(--mantine-color-dark-2));
    margin-right: var(--mantine-spacing-sm);
    width: 25px;
    height: 25px;
}